import React from "react"
import { Link as GatsbyLink } from "gatsby"
import { useLang } from "../hooks/useLang"

const Link = ({ to, ...rest }) => {
  const lang = useLang()
  return <GatsbyLink to={`${lang !== "pl" ? `/${lang}` : ""}${to}`} {...rest} />
}

export const getLink = (link, lang) => (lang === "pl" ? link : `/en${link}`)

export default Link
