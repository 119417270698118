require("prismjs/themes/prism-tomorrow.css")
require("prismjs/plugins/command-line/prism-command-line.css")
require("plyr/dist/plyr.css")

exports.onClientEntry = () => {
  const userLang = navigator.language.substr(0, 2)

  if (userLang !== "pl" && !window.location.pathname.startsWith("/en")) {
    window.location.pathname = `/en${window.location.pathname}`
  }
}
