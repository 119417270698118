import React from "react"

export default () => {
  const [enterDate, setEnterDate] = React.useState()
  const [counter, setCounter] = React.useState(0)

  React.useEffect(() => {
    setEnterDate(Date.now())

    const intervalId = setInterval(() => {
      setCounter(prev => prev + 1)
    }, 1000)

    return () => clearInterval(intervalId)
  }, [])

  return (
    <div
      style={{
        textAlign: "center",
        margin: "2em 0",
        border: "1px solid",
        padding: "2em",
      }}
    >
      <p>
        Enter time: <span data-testid="enter-time">{enterDate}</span>
      </p>
      <p>
        Time on page: <span data-testid="counter">{counter}</span>
      </p>
    </div>
  )
}
