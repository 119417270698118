import styled from "@emotion/styled"
import Img from "gatsby-image"

export const Title = styled.h1`
  font-weight: 700;
  font-size: 36px;
  line-height: 48px;
  text-align: center;
  margin-bottom: 0;
  color: ${p => p.theme.colors.title};
`

export const CreditInner = styled.div`
  position: relative;
  display: inline-block;
  padding: 0 15px;
  &:after {
    content: "";
    display: block;
    height: 1px;
    width: 125px;
    background-color: ${p => p.theme.colors.border};
    position: absolute;
    left: 100%;
    top: 15px;
  }
  &:before {
    content: "";
    display: block;
    height: 1px;
    width: 125px;
    background-color: ${p => p.theme.colors.border};
    position: absolute;
    right: 100%;
    top: 15px;
  }

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 1;
    color: ${p => p.theme.colors.regular};
    display: inline-block;
    text-transform: capitalize;
  }
`

export const Credit = styled.div`
  text-align: center;
  margin-bottom: 20px;
  overflow: hidden;
`

export const Image = styled(Img)`
  margin-bottom: 50px;
  max-height: 550px;
  border-radius: 5px;
`

export const Video = styled.div`
  margin-bottom: 50px;
  max-height: 550px;
  border-radius: 5px;
`
