import { css } from "@emotion/core"

export const input = (theme, height) => css`
  background-color: transparent;
  border-radius: 2px;
  border: 1px solid #edf0f5;
  padding: 0 20px;
  width: 100%;
  transition: border 0.3s ease;
  ${height ? `height: ${height};` : ""}

  font-size: 16px;
  color: ${theme.colors.regular};

  &:focus {
    outline: 0;
    box-shadow: none;
    border-color: ${theme.colors.disable};
  }
`
