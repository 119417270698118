import { graphql, useStaticQuery } from "gatsby"

function useSocials() {
  const {
    site: {
      siteMetadata: { socials },
    },
  } = useStaticQuery(graphql`
    query SocialsQuery {
      site {
        siteMetadata {
          socials {
            twitter
            youtube
            github
            linkedin
            dev
          }
        }
      }
    }
  `)

  return socials
}

export default useSocials
