import { css } from "@emotion/core"

export const normalBtn = theme => css`
  background-color: ${theme.colors.accent};
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  border-radius: 0;
  padding: 10px 30px;
  text-transform: uppercase;
  border: 2px solid ${theme.colors.accent};
  transition: 0.3s ease;
  display: inline-block;

  &:hover {
    background-color: #fff;
    color: ${theme.colors.accent};
    text-decoration: none;
    border: 2px solid ${theme.colors.accent};
  }
`
export const iconBtn = theme => css`
  background-color: #edf0f5;
  color: ${theme.colors.title};
  font-size: 14px;
  border-radius: 999px;
  border: 2px solid #edf0f5;
  padding: 7px;
  display: inline-block;

  &:hover {
    background-color: ${theme.colors.accent};
    color: #fff;
    border: 2px solid ${theme.colors.accent};
    text-decoration: none;
  }

  font-family: inherit;
  height: 40px;
  width: 40px;
  text-align: center;
  margin-right: 7px;
  &:last-child {
    margin-right: 0;
  }
`
