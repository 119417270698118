const mobile = 576
const tablet = 768
const desktop = 992
const largeDesktop = 1200
const xlargeDesktop = 1400
const xxlargeDesktop = 1770

export default {
  mobile: `@media (max-width: ${mobile}px)`,
  tablet: `@media (min-width: ${mobile}px)`,
  smallDesktop: `@media (min-width: ${tablet}px)`,
  desktop: `@media (min-width: ${desktop}px)`,
  mediumDesktop: `@media (min-width: ${largeDesktop}px)`,
  largeDesktop: `@media (min-width: ${xlargeDesktop}px)`,
  extraLargeDesktop: `@media (min-width: ${xxlargeDesktop}px)`,
}
