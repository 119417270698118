import React from "react"
import "@fortawesome/fontawesome-svg-core/styles.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { library } from "@fortawesome/fontawesome-svg-core"
import { faBars, faPaperPlane, faRss } from "@fortawesome/free-solid-svg-icons"
import {
  faTwitter,
  faGithub,
  faYoutube,
  faLinkedin,
  faDev,
} from "@fortawesome/free-brands-svg-icons"

library.add(
  faBars,
  faTwitter,
  faGithub,
  faYoutube,
  faLinkedin,
  faPaperPlane,
  faRss,
  faDev
)

export const FaIcon = props => <FontAwesomeIcon {...props} />
