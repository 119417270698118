module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-157830572-1"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":1170,"showCaptions":true}},{"resolve":"gatsby-remark-prismjs","options":{"noInlineHighlight":true}},"gatsby-remark-external-links"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"przemuh.dev","short_name":"przemuh.dev","start_url":"/","background_color":"#f17070","theme_color":"#f17070","display":"minimal-ui","icon":"src/images/sp.jpg"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
