import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { css } from "@emotion/core"
import styled from "@emotion/styled"
import { useLang } from "../../hooks/useLang"
import { Credit, CreditInner, Title, Image } from "../post/details"
import { Container, Row, Col } from "../grid"
import { FaIcon } from "../icon"
import mq from "../layout/mq"
import useSocials from "../../hooks/useSocials"
import { useTheme } from "emotion-theming"
import { normalBtn } from "../button"
import { input } from "../input"
import { getLink } from "../link"

const trans = {
  pl: {
    about: "O mnie",
    leaveMessage: "Napisz do mnie",
    answer: "Zawsze odpowiem...czasem z opóźnieniem, ale odpowiem :)",
    name: "Imię",
    email: "Email",
    message: "Treść wiadomości",
    send: "Wyślij wiadomość",
  },
  en: {
    about: "About Me",
    leaveMessage: "Leave Message",
    answer: "I always answer...sometimes with a little delay, but always :)",
    name: "Name",
    email: "Email",
    message: "Message",
    send: "Send message",
  },
}

const Header = ({ children }) => {
  const lang = useLang()

  const { cover } = useStaticQuery(graphql`
    query {
      cover: file(name: { eq: "meetjs-2018-2" }) {
        childImageSharp {
          fluid(maxWidth: 1770, maxHeight: 510) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <>
      <Title>{trans[lang].about}</Title>
      <Credit>
        <CreditInner>
          <span
            css={css`
              line-height: 30px;
            `}
          >
            przemuh.dev
          </span>
        </CreditInner>
      </Credit>
      <Image
        css={css`
          margin-bottom: 20px;
        `}
        fluid={cover.childImageSharp.fluid}
      />
      {children}
    </>
  )
}

export const About = ({ children }) => (
  <section
    css={css`
      margin-bottom: 70px;
    `}
  >
    <Container>
      <Header>{children}</Header>
    </Container>
  </section>
)

const ContactIcon = styled.a`
  color: ${p => p.theme.colors.accent};
  margin: 0 auto 20px;
  background-color: #fff;
  box-shadow: 0 4px 18px 0 rgba(32, 45, 73, 0.1);
  border-radius: 999px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;

  &:hover {
    background-color: ${p => p.theme.colors.accent};
    color: #fff;
  }

  font-size: 16px;
  width: 40px;
  height: 40px;

  ${mq.tablet} {
    width: 80px;
    height: 80px;
    font-size: 32px;
    margin-bottom: 40px;
  }
`

const ContactDetail = styled.div`
  font-size: 8px;

  ${mq.tablet} {
    font-size: 10px;
  }

  h3 {
    font-weight: 700;
    font-size: 1.6em;
    line-height: 1;
    margin-bottom: 10px;
    color: ${p => p.theme.colors.title};
  }
  p {
    font-weight: 400;
    font-size: 1.8em;
    line-height: 26px;
    color: ${p => p.theme.colors.regular};
  }
`

const Circle = ({ children, icon, href, offset = 0 }) => (
  <Col xs={{ span: 2, offset }}>
    <div
      css={css`
        text-align: center;
      `}
    >
      <ContactIcon href={href} rel="noopener noreferrer" target="_blank">
        <FaIcon icon={icon} />
      </ContactIcon>
      <ContactDetail>{children}</ContactDetail>
    </div>
  </Col>
)

export const Contact = () => {
  const socials = useSocials()
  const lang = useLang()
  const theme = useTheme()

  return (
    <>
      <section
        css={css`
          padding-bottom: 10px;
          margin-bottom: 20px;
          background-color: #f9f9f9;

          ${mq.tablet} {
            padding-bottom: 20px;
            margin-bottom: 40px;
          }
        `}
      >
        <Container>
          <Row
            css={css`
              position: relative;
              top: -20px;

              ${mq.tablet} {
                top: -40px;
              }
            `}
          >
            <Circle icon={["fab", "twitter"]} href={socials.twitter} offset={1}>
              <h3>Twitter</h3>
            </Circle>
            <Circle icon={["fab", "youtube"]} href={socials.youtube}>
              <h3>Youtube</h3>
            </Circle>
            <Circle icon={["fab", "github"]} href={socials.github}>
              <h3>Github</h3>
            </Circle>
            <Circle icon={["fab", "linkedin"]} href={socials.linkedin}>
              <h3>LinkedIn</h3>
            </Circle>
            <Circle icon={["fab", "dev"]} href={socials.dev}>
              <h3>Dev</h3>
            </Circle>
          </Row>
        </Container>
      </section>
      <section
        id="contact-form"
        css={css`
          margin-bottom: 90px;
        `}
      >
        <Container>
          <Row>
            <Col xs={12}>
              <div className="text-center">
                <h2
                  css={css`
                    font-size: 36px;
                    line-height: 1;
                    margin-bottom: 25px;
                  `}
                >
                  {trans[lang].leaveMessage}
                </h2>
                <p
                  css={css`
                    font-size: 16px;
                    line-height: 1;
                    margin-bottom: 50px;
                  `}
                >
                  {trans[lang].answer}
                </p>
                <form
                  name="contact"
                  method="post"
                  action={getLink("/success-contact", lang)}
                  data-netlify="true"
                >
                  <input type="hidden" name="form-name" value="contact" />
                  <div
                    css={css`
                      margin-bottom: 50px;
                    `}
                    className="form-row"
                  >
                    <Col md={6} className="form-group">
                      <input
                        css={input(theme, "45px")}
                        id="name"
                        name="name"
                        type="text"
                        placeholder={trans[lang].name}
                        required
                      />
                    </Col>
                    <Col md={6} className="form-group">
                      <input
                        css={input(theme, "45px")}
                        id="email"
                        name="email"
                        type="email"
                        placeholder={trans[lang].email}
                        required
                      />
                    </Col>
                    <Col xs={12}>
                      <textarea
                        css={css`
                          ${input(theme)};
                          padding: 20px;
                        `}
                        id="message"
                        name="message"
                        cols="30"
                        rows="6"
                        placeholder={trans[lang].message}
                      />
                    </Col>
                  </div>
                  <button css={normalBtn(theme)} type="submit">
                    {trans[lang].send}
                  </button>
                </form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}
